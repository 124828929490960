<template>
  <div class="interview">
    <!-- 待面试人员 -->
    <van-nav-bar
      :title="$t('confirmInterview.confirmTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    >
      <template #right>
        <div class="mult_switch">
          <van-switch
            v-model="multiple"
            size="20px"
            active-color="#13ce66"
            inactive-color="#dcdee0"
          />
          <span class="text" @click="multiple = !multiple">多选</span>
        </div>
        <!-- <van-checkbox v-model="multiple"
                      shape="square">多选</van-checkbox> -->
      </template>
    </van-nav-bar>
    <div class="fixedH" style="height: 1.22667rem"></div>
    <div class="content Interviewers_cont">
      <van-checkbox-group
        v-model="checked"
        v-if="multiple"
        style="margin-top: 0.32rem"
      >
        <van-checkbox
          :name="item"
          v-for="(item, index) in viewList"
          :key="index"
        >
          <template #default>
            <div class="viewZp">
              <svg-icon
                style="width: 100%; height: 100%"
                icon-class="default_avator"
              />
            </div>
            <div style="width: 100%; min-height: 2.25rem">
              <div class="row1">{{ item.empname }}</div>
              <div class="row2">
                <span
                  >{{ item.workyear
                  }}{{ $t('confirmInterview.confirmTxt2') }}</span
                >
                <span>{{ item.degree }}</span>
              </div>
              <div class="row3">
                <span
                  >{{ $t('confirmInterview.confirmTxt3')
                  }}{{ item.staname }}</span
                >
              </div>
              <div class="row4">
                <div class="row4_left">
                  <span>{{ item.telephone }}</span>
                  <svg-icon icon-class="interview_tel"></svg-icon>
                  <svg-icon icon-class="interview_wx"></svg-icon>
                </div>
              </div>
            </div>
          </template>
        </van-checkbox>
      </van-checkbox-group>
      <ul v-else style="margin-top: 0.32rem">
        <li
          v-for="(item, index) in viewList"
          :key="index"
          @click="toViewInfo(item, index)"
        >
          <div class="viewZp">
            <svg-icon
              style="width: 100%; height: 100%"
              icon-class="default_avator"
            />
          </div>
          <div style="width: 100%; min-height: 2.25rem">
            <div class="row1">
              {{ item.empname }}
              <span style="color: #fec000; font-size: 14px"
                >（{{ $t('confirmInterview.confirmTxt14') }}）</span
              >
            </div>
            <div class="row2">
              <span
                >{{ item.workyear
                }}{{ $t('confirmInterview.confirmTxt2') }}</span
              >
              <span>{{ item.degree }}</span>
            </div>
            <div class="row3">
              <span
                >{{ $t('confirmInterview.confirmTxt3')
                }}{{ item.staname }}</span
              >
            </div>
            <div class="row4">
              <div class="row4_left">
                <span>{{ item.telephone }}</span>
                <svg-icon icon-class="interview_tel"></svg-icon>
                <svg-icon icon-class="interview_wx"></svg-icon>
              </div>
            </div>
          </div>

          <div class="row5">
            <span @click.stop="mscp(item, index)">{{
              $t('confirmInterview.confirmTxt4')
            }}</span>
            <span @click.stop="mspj(item, index)">{{
              $t('confirmInterview.confirmTxt5')
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="viewFooter" v-if="multiple">
      <span @click="signView">{{
        $t('confirmInterviewInfo.confirmInfoTxt32')
      }}</span>
      <span @click="zitoElimination">{{
        $t('confirmInterviewInfo.confirmInfoTxt33')
      }}</span>
    </div>
    <!-- 同意面试弹窗 -->
    <van-dialog
      v-model="showDia"
      :title="$t('confirmInterview.confirmTxt4')"
      show-cancel-button
      :confirm-button-text="$t('module.confirm')"
      :cancel-button-text="$t('module.cancel')"
      :before-close="closeDia"
    >
      <div style="padding: 10px">
        <van-field
          v-if="ag_times_count && ag_times_count != 0"
          readonly
          required
          clickable
          name="datetimePicker"
          :value="iv_time"
          :label="$t('confirmInterview.confirmTxt15')"
          :placeholder="$t('module.SelectDate')"
          @click="showIvDatePicker(1)"
        />
        <van-field
          v-if="ag_times_count && ag_times_count != 0"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_bt1"
          :label="$t('confirmInterview.confirmTxt18') + 1"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_bt1')"
        />
        <van-field
          v-if="ag_times_count && ag_times_count != 0"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_et1"
          :label="$t('confirmInterview.confirmTxt19') + 1"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_et1')"
          style="margin-bottom: 10px;"
        />
        <van-field
          v-if="ag_times_count >= 2"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_time2"
          :label="$t('confirmInterview.confirmTxt16')"
          :placeholder="$t('module.SelectDate')"
          @click="showIvDatePicker(2)"
        />
        <van-field
          v-if="ag_times_count >= 2"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_bt2"
          :label="$t('confirmInterview.confirmTxt18') + 2"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_bt2')"
        />
        <van-field
          v-if="ag_times_count >= 2"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_et2"
          :label="$t('confirmInterview.confirmTxt19') + 2"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_et2')"
          style="margin-bottom: 10px;"
        />
        <van-field
          v-if="ag_times_count == 3"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_time3"
          :label="$t('confirmInterview.confirmTxt17')"
          :placeholder="$t('module.SelectDate')"
          @click="showIvDatePicker(3)"
        />
        <van-field
          v-if="ag_times_count == 3"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_bt3"
          :label="$t('confirmInterview.confirmTxt18') + 3"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_bt3')"
        />
        <van-field
          v-if="ag_times_count == 3"
          readonly
          clickable
          name="datetimePicker"
          :value="iv_et3"
          :label="$t('confirmInterview.confirmTxt19') + 3"
          :placeholder="$t('module.SelectDateTime')"
          @click="showIvTimePicker('iv_et3')"
        />
      </div>
    </van-dialog>
    <!-- 不同意面试弹窗 -->
    <van-dialog
      v-model="showBTYDia"
      :title="$t('confirmInterview.confirmTxt5')"
      show-cancel-button
      :confirm-button-text="$t('module.confirm')"
      :cancel-button-text="$t('module.cancel')"
      :before-close="closeBTYDia"
    >
      <div style="padding: 10px">
        <van-cell
          class="desc_cell"
          required
          :value="$t('confirmInterview.confirmTxt11')"
        />
        <van-field
          class="desc_field"
          v-model="reason"
          rows="3"
          autosize
          :readonly="reasonReadonly"
          type="textarea"
          :placeholder="$t('confirmInterview.confirmTxt11')"
          @click="getReasonPicker"
        />
      </div>
    </van-dialog>
    <!-- 面试日期弹窗 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 面试时间弹窗 -->
    <van-popup v-model="showTimePic" round position="bottom">
      <van-datetime-picker
        type="time"
        @confirm="onConfirmByTime"
        @cancel="showTimePic = false"
      />
    </van-popup>
    <!-- 不同意面试原因弹窗 -->
    <van-popup v-model="showReasonPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="Reasoncolumns"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="showReasonPicker = false"
        @confirm="onConfirmReason"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getIvCheckList,
  checkIv,
  unCheckIv,
  getOtherParamInfo
} from '@api/wxzp.js'
import { GetFilterDropDownList } from '@api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { Dialog, Toast } from 'vant'
export default {
  data() {
    return {
      show: false,
      userInfo,
      yymmdd: [],
      viewList: [],
      yymm: '',
      nowselectData: '',
      setData: '',
      showDia: false,
      showPicker: false,
      minDate: new Date(),
      maxDate: new Date(2121, 11, 31),
      autoid: '',
      iv_time: '',
      showBTYDia: false,
      reason: '', // 不同意原因
      reasonReadonly: true,
      showReasonPicker: false,
      Reasoncolumns: [],
      iv_time2: null,
      iv_time3: null,
      chooseTimer: 1,
      multiple: false,
      checked: [], // 选中的记录
      operationType: 'one', // 同意或者不同意的对象是单数还是多数
      iv_bt1: '',
      iv_et1: '',
      iv_bt2: '',
      iv_et2: '',
      iv_bt3: '',
      iv_et3: '',
      whichTime: '',
      showTimePic: false,
      ag_times_count: ''
    }
  },
  watch: {
    multiple(n) {
      const cont = document.getElementsByClassName('Interviewers_cont')[0]
      if (n) {
        if (cont) {
          cont.style.height = 'calc(100% - 106px)'
        }
      } else {
        if (cont) {
          cont.style.height = 'calc(100% - 46px)'
        }
      }
    }
  },
  created() {
    this.getdate()
    getOtherParamInfo().then(res => {
      let datas = res.data[0]
      this.ag_times_count = datas.ag_times_count
    })
  },
  methods: {
    // 多选模式下的同意面试
    signView() {
      if (this.checked.length > 0) {
        this.operationType = 'many'
        this.iv_time = ''
        this.iv_time2 = ''
        this.iv_time3 = ''
        this.showDia = true
      } else {
        Toast.fail('请选择至少一条记录！')
      }
    },
    // 多选模式下的不同意面试
    zitoElimination() {
      if (this.checked.length > 0) {
        this.operationType = 'many'
        this.reason = ''
        this.showBTYDia = true
      } else {
        Toast.fail('请选择至少一条记录！')
      }
    },
    // 打开面试日期pop
    showIvDatePicker(val) {
      this.chooseTimer = val
      this.showPicker = true
    },
    // 打开面试时间pop
    showIvTimePicker(val) {
      this.whichTime = val
      this.showTimePic = true
    },
    // 打开不同意面试原因弹窗
    getReasonPicker() {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: '@安排面试不同意原因'
      }).then(res => {
        if (res.data.length > 0) {
          this.Reasoncolumns = []
          for (let i = 0; i < res.data.length; i++) {
            this.Reasoncolumns.push(res.data[i].dicvalue)
          }
          this.showReasonPicker = true
        } else {
          this.showReasonPicker = false
          this.reasonReadonly = false
        }
        // console.log(res)
      })
    },
    // 不同意面试原因弹窗回调
    onConfirmReason(value) {
      this.reason = value
      this.showReasonPicker = false
      this.Reasoncolumns = []
    },
    // 不够就补零
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate(date, rule) {
      if (date) {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd') {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else {
        return ''
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    closeEwm() {
      this.show = false
    },
    mspj(ite, idx) {
      this.operationType = 'one'
      this.autoid = ite.autoid
      this.reason = ''
      this.showBTYDia = true
      // Dialog.confirm({
      //   title: this.$t('confirmInterview.confirmTxt7'),
      //   message: this.$t('confirmInterview.confirmTxt6'),
      // }).then(() => {
      //   unCheckIv({
      //     autoid: ite.autoid,
      //     username: this.userInfo.username
      //   }).then(res => {
      //     Toast(this.$t('confirmInterview.confirmTxt8'));
      //     this.getdate();
      //   })
      // }).catch(() => { });
    },
    // 不同意弹窗回调
    async closeBTYDia(action, done) {
      if (action == 'confirm') {
        // 多选模式
        if (this.operationType == 'many') {
          let idx = 0
          if (this.reason) {
            for (const it of this.checked) {
              await unCheckIv({
                autoid: it.autoid,
                reason: this.reason,
                username: this.userInfo.username
              }).then(res => {
                idx++
              })
            }
            if (idx == this.checked.length) {
              done()
              Toast(this.$t('confirmInterview.confirmTxt8'))
              this.getdate()
            }
          } else {
            Toast.fail(this.$t('confirmInterview.confirmTxt12'))
            done(false)
          }
        } else {
          if (this.reason) {
            unCheckIv({
              autoid: this.autoid,
              reason: this.reason,
              username: this.userInfo.username
            }).then(res => {
              done()
              Toast(this.$t('confirmInterview.confirmTxt8'))
              this.getdate()
            })
          } else {
            Toast.fail(this.$t('confirmInterview.confirmTxt12'))
            done(false)
          }
        }
      } else {
        done()
      }
    },
    mscp(ite, idx) {
      this.operationType = 'one'
      this.autoid = ite.autoid
      this.iv_time = ''
      this.iv_bt1 = ''
      this.iv_et1 = ''
      this.iv_time2 = ''
      this.iv_bt2 = ''
      this.iv_et2 = ''
      this.iv_time3 = ''
      this.iv_bt3 = ''
      this.iv_et3 = ''
      this.showDia = true
      // Dialog.confirm({
      //   title: this.$t('confirmInterview.confirmTxt7'),
      //   message: this.$t('confirmInterview.confirmTxt9'),
      // }).then(() => {
      //   checkIv({
      //     autoid: ite.autoid,
      //     username: this.userInfo.username
      //   }).then(res => {
      //     Toast(this.$t('confirmInterview.confirmTxt8'));
      //     this.getdate();
      //   })
      // }).catch(() => { });
    },
    // 日期选择关闭回调
    onConfirm(time) {
      if (this.chooseTimer == 1) {
        this.iv_time = this.formatDate(time, 'yyyy-MM-dd')
      } else if (this.chooseTimer == 2) {
        this.iv_time2 = this.formatDate(time, 'yyyy-MM-dd')
      } else if (this.chooseTimer == 3) {
        this.iv_time3 = this.formatDate(time, 'yyyy-MM-dd')
      }
      this.showPicker = false
    },
    // 时间选择回调
    onConfirmByTime(time) {
      if (this.whichTime == 'iv_bt1') {
        this.iv_bt1 = time
      } else if (this.whichTime == 'iv_et1') {
        this.iv_et1 = time
      } else if (this.whichTime == 'iv_bt2') {
        this.iv_bt2 = time
      } else if (this.whichTime == 'iv_et2') {
        this.iv_et2 = time
      } else if (this.whichTime == 'iv_bt3') {
        this.iv_bt3 = time
      } else if (this.whichTime == 'iv_et3') {
        this.iv_et3 = time
      }
      this.showTimePic = false
    },
    // 同意弹窗关闭回调
    async closeDia(action, done) {
      if (action == 'confirm') {
        if (!this.iv_time && this.ag_times_count && this.ag_times_count != 0) {
          Toast.fail('请选择面试时间1！')
          done(false)
          return
        }
        // 多选模式
        if (this.operationType == 'many') {
          let idx = 0
          for (const it of this.checked) {
            await checkIv({
              autoid: it.autoid,
              iv_datetime: this.iv_time,
              iv_datetime2: this.iv_time2,
              iv_datetime3: this.iv_time3,
              iv_bt1: this.iv_bt1,
              iv_et1: this.iv_et1,
              iv_bt2: this.iv_bt2,
              iv_et2: this.iv_et2,
              iv_bt3: this.iv_bt3,
              iv_et3: this.iv_et3,
              username: this.userInfo.username
            }).then(res => {
              idx++
            })
          }
          if (idx == this.checked.length) {
            done()
            Toast(this.$t('confirmInterview.confirmTxt8'))
            this.getdate()
          }
        } else {
          // 单选
          // if (this.iv_time)
          // {
          checkIv({
            autoid: this.autoid,
            iv_datetime: this.iv_time,
            iv_datetime2: this.iv_time2,
            iv_datetime3: this.iv_time3,
            iv_bt1: this.iv_bt1,
            iv_et1: this.iv_et1,
            iv_bt2: this.iv_bt2,
            iv_et2: this.iv_et2,
            iv_bt3: this.iv_bt3,
            iv_et3: this.iv_et3,
            username: this.userInfo.username
          }).then(res => {
            done()
            Toast(this.$t('confirmInterview.confirmTxt8'))
            this.getdate()
          })
          // } else
          // {
          //   Toast.fail(this.$t('confirmInterview.confirmTxt13'))
          //   done(false)
          // }
        }
      } else {
        done()
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    onClickRight() {},
    toViewInfo(ite, idx) {
      this.$router.push({
        path: '/confirmInterviewInfo',
        query: { autoid: ite.autoid }
      })
    },
    async getdate() {
      let res = await getIvCheckList({
        empid: this.userInfo.empid
      })
      // console.log(res.data)
      this.viewList = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.interview {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      .mult_switch {
        display: flex;
        align-items: center;
        .text {
          margin-left: 5px;
          color: #ffffff;
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .content {
    height: calc(100% - 92px);
    overflow: auto;
    .van-checkbox-group {
      .van-checkbox {
        padding: 20px 10px;
        padding-bottom: 0;
        background: #fff;
        box-sizing: border-box;
        margin-bottom: 24px;
        align-items: unset;
        /deep/.van-checkbox__label {
          position: relative;
          width: 100%;
          .viewZp {
            position: absolute;
            top: 38px;
            right: 30px;
            width: 1.8rem;
            height: 1.8rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .row1 {
            height: 40px;
            line-height: 40px;
            font-size: 40px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            margin-bottom: 20px;
            line-height: 1;
          }
          .row2 {
            font-size: 28px;
            font-family: Source Han Sans CN;
            color: #666;
            margin-bottom: 20px;
            line-height: 1;
            span:first-child {
              padding-right: 14px;
              border-right: 1px solid #999;
            }
            span:last-child {
              margin-left: 10px;
            }
          }
          .row3 {
            font-size: 28px;
            font-family: Source Han Sans CN;
            color: #999;
            margin-bottom: 20px;
          }
          .row4 {
            display: flex;
            justify-content: space-between;
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1;
            .row4_left {
              color: #2b8df0;
              span {
                margin-right: 10px;
              }
              .svg-icon {
                font-size: 36px;
                margin: 0 5px;
              }
            }
            .row4_right {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
            }
          }
          .row5 {
            display: flex;
            border-top: 1px solid #ccc;
            padding: 15px;
            span {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #2b8df0;
            }
            span:first-child {
              border-right: 1px solid #cccccc;
            }
          }
        }
      }
    }
    .van-calendar {
      margin-bottom: 24px;
    }
    ul {
      li {
        position: relative;
        padding: 20px 30px;
        padding-bottom: 0;
        // height: 378px;
        background: #fff;
        box-sizing: border-box;
        margin-bottom: 24px;
        .viewZp {
          position: absolute;
          top: 38px;
          right: 30px;
          width: 1.8rem;
          height: 1.8rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .row1 {
          height: 40px;
          line-height: 40px;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 1;
        }
        .row2 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
          margin-bottom: 20px;
          line-height: 1;
          span:first-child {
            padding-right: 14px;
            border-right: 1px solid #999;
          }
          span:last-child {
            margin-left: 10px;
          }
        }
        .row3 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #999;
          margin-bottom: 20px;
        }
        .row4 {
          display: flex;
          justify-content: space-between;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 30px;
          line-height: 1;
          .row4_left {
            color: #2b8df0;
            span {
              margin-right: 10px;
            }
            .svg-icon {
              font-size: 36px;
              margin: 0 5px;
            }
          }
          .row4_right {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }
        }
        .row5 {
          display: flex;
          border-top: 1px solid #ccc;
          padding: 15px;
          span {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          span:first-child {
            border-right: 1px solid #cccccc;
          }
        }
      }
    }
  }
  .viewFooter {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    span {
      display: inline-block;
      width: 50%;
      height: 100%;
      line-height: 80px;
      box-sizing: border-box;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2b8df0;
      text-align: center;
    }
    span:first-child {
      flex: 1;
      border-right: 1px solid #ccc;
    }
    span:nth-child(2) {
      flex: 1;
      color: #f53838;
      // border-right:1px solid #ccc;
    }
  }
}
.limitclick {
  color: #ccc !important;
}
</style>
